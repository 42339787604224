<template>
  <div class="back">
    <div class="container container-fluid mt-5 pt-5">
      <div
        class="container container-fluid pb-5"
        style="background: #263238; border-radius: 15px"
      >
        <div class="text-center mb-3 pt-4 mt-2">
          <h1 class="font-weight-bold white-text green-1">
            CERCAS Y MALLAS VERSATILES
          </h1>
          <h3 class="green-text font-weight-bold">FOLLAJE ARTIFICIAL</h3>
        </div>
        <div class="container d-flex align-items-center justify-content-center">
          <ul class="carousel-2 my-carousel-2">
            <input
              class="carousel-2__activator"
              type="radio"
              id="A"
              name="activator"
              checked="checked"
            />
            <input
              class="carousel-2__activator"
              type="radio"
              id="B"
              name="activator"
            />
            <input
              class="carousel-2__activator"
              type="radio"
              id="Col"
              name="activator"
            />
            <input
              class="carousel-2__activator"
              type="radio"
              id="D"
              name="activator"
            />
            <input
              class="carousel-2__activator"
              type="radio"
              id="E"
              name="activator"
            />
            <div class="carousel-2__controls">
              <label
                class="carousel-2__control carousel-2__control--backward"
                for="E"
              ></label>
              <label
                class="carousel-2__control carousel-2__control--forward"
                for="B"
              ></label>
            </div>
            <div class="carousel-2__controls">
              <label
                class="carousel-2__control carousel-2__control--backward"
                for="A"
              ></label>
              <label
                class="carousel-2__control carousel-2__control--forward"
                for="Col"
              ></label>
            </div>
            <div class="carousel-2__controls">
              <label
                class="carousel-2__control carousel-2__control--backward"
                for="B"
              ></label>
              <label
                class="carousel-2__control carousel-2__control--forward"
                for="D"
              ></label>
            </div>
            <div class="carousel-2__controls">
              <label
                class="carousel-2__control carousel-2__control--backward"
                for="Col"
              ></label>
              <label
                class="carousel-2__control carousel-2__control--forward"
                for="E"
              ></label>
            </div>
            <div class="carousel-2__controls">
              <label
                class="carousel-2__control carousel-2__control--backward"
                for="D"
              ></label>
              <label
                class="carousel-2__control carousel-2__control--forward"
                for="A"
              ></label>
            </div>
            <li class="carousel-2__slide">
              <h1 class="car"></h1>
              <video class="video-intro d-none d-sm-none d-md-block" autoPlay controls style="width: 100%">
                <source src="../assets/follage2.mp4" type="video/mp4" />
              </video>
            </li>
            <li class="carousel-2__slide">
              <h1 class="car">Con Toques Naturales y Frescos</h1>
            </li>
            <li class="carousel-2__slide">
              <h1 class="car">De Fácil Mantenimiento y Alta Duración</h1>
            </li>
            <li class="carousel-2__slide">
              <h1 class="car">Follaje Artificial Resistentes a los Cambios de Temperatura</h1>
            </li>
            <li class="carousel-2__slide">
              <h1 class="car">Tratamientos Especiales Contra Rayos UV</h1>
            </li>
            <div class="carousel-2__indicators">
              <label class="carousel-2__indicator" for="A"></label>
              <label class="carousel-2__indicator" for="B"></label>
              <label class="carousel-2__indicator" for="Col"></label>
              <label class="carousel-2__indicator" for="D"></label>
              <label class="carousel-2__indicator" for="E"></label>
            </div>
          </ul>
        </div>
        <div class="mt-5 container">
          <div class="row d-flex align-items-center">
            <div class="col-md-8">
              <mdb-card class="mb-5 green" data-aos="fade-up">
                <mdb-card-body>
                  <div class="text-center mb-1">
                    <h3 class="h3-w font-weight-bold">
                      Somos diseñadores, instaladores y proveedores de follajes
                      artificiales para azoteas jardinadas y jardines
                      residenciales y corporativos
                    </h3>
                  </div>
                  <hr class="my-hr-2" />
                  <div class="mt-3 mx-4">
                    <p class="">
                      Los follajes artificiales nos proporcionan una estética
                      similar al jardín vivo pero sin nigún mantenimiento, con
                      menos inversión y con un sencillo método de conservación
                      (para eliminar el polvo ambiental que se pueda acumular en
                      el mismo), utiliza elementos artificiales como musgos y
                      plantas variadas de un gran realismo que permiten un gran
                      número de posibilidades y detalles que naturalizan el
                      diseño y acabado final, además la obra es inmutable y
                      guarda todo el tiempo la apariencia inicial en el diseño.
                    </p>
                  </div>
                  <div class="text-center mt-2 mb-2">
          </div>
                </mdb-card-body>
              </mdb-card>
            </div>
            <div class="col-md-4 mb-4 text-center" data-aos="fade-up">
              <h3 class="h3-w font-weight-bold mb-3">Muros verdes</h3>
              <img src="../assets/follaje/10.jpg" alt="" class="img-fluid rounded">
              <a target="_blank" style="color: #fff;" @click="modalf = true"><mdb-btn class="mt-3" outline="white" rounded>Mas información<mdb-icon icon="angle-up" class="ml-2" /></mdb-btn></a>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/follaje/follaje/3.jpg')"
                  alt="zoom"
                >
                </mdb-view>
            </div>
            <div class="col-md-3">
              <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/follaje/follaje/7.jpg')"
                  alt="zoom"
                >
                </mdb-view>
            </div>
            <div class="col-md-3">
              <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/follaje/1.jpg')"
                  alt="zoom"
                >
                </mdb-view>
            </div>
            <div class="col-md-3">
              <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/follaje/follaje/10.jpg')"
                  alt="zoom"
                >
                </mdb-view>
            </div>
          </div>
          <mdb-card class="danger-color mb-5">
            <mdb-card-body>
              <div class="text-center mb-3">
                <h4 class="font-weight-bold white-text">
                  Beneficios de los Follajes Artificiales
                </h4>
              </div>
              <mdb-tbl class="white-text" responsive>
                <mdb-tbl-body>
                  <tr>
                    <td class="white-text">Lucen como naturales</td>
                    <td class="white-text">
                      Se pueden colocar casi sibre cualquier muro, malla
                      (ciclónica, reja, etc)
                    </td>
                    <td class="white-text">Cuenta con protección UV</td>
                  </tr>
                  <tr>
                    <td class="white-text">Aislante acústico y térmico</td>
                    <td class="white-text">
                      No requiere mantenimiento, abono ni riego
                    </td>
                    <td class="white-text">No es tóxico, ni venenoso</td>
                  </tr>
                  <tr>
                    <td class="white-text">
                      No requiere de luz ni ventilación
                    </td>
                    <td class="white-text">
                      Da una apariencia natural de una forma limpia y libre de
                      insectos
                    </td>
                    <td class="white-text">
                      Es hipoalergénico ya que no desprende polen
                    </td>
                  </tr>
                  <tr>
                    <td class="white-text">
                      No es atractivo para las mascotas
                    </td>
                    <td class="white-text">
                      Ahorras agua y de esta forma economizas y contribuyes al
                      medio ambiente
                    </td>
                    <td class="white-text">Tiene garantía</td>
                  </tr>
                </mdb-tbl-body>
              </mdb-tbl>
            </mdb-card-body>
          </mdb-card>
          <div>
            <div class="row">
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/follaje/follaje/2.jpg')"
                  alt="zoom"
                >
  
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/follaje/follaje/11.jpg')"
                  alt="zoom"
                >
  
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/follaje/follaje/4.jpg')"
                  alt="zoom"
                >
  
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/follaje/follaje/12.jpg')"
                  alt="zoom"
                >
  
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/follaje/follaje/6.jpg')"
                  alt="zoom"
                >
  
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/follaje/follaje/9.jpg')"
                  alt="zoom"
                >
  
                </mdb-view>
              </div>
            </div>
          </div>
          <div class="mt-3 white-text">
            <a target="_blank" href="https://api.whatsapp.com/message/OM43BU5OG5GNE1"
              ><mdb-btn block color="success" class="mb-3 font-weight-bold" data-aos="zoom-in"
                >Contactanos
                <mdb-icon fab icon="whatsapp" class="ml-2" /></mdb-btn
            ></a>
            <div class="row">
              <div class="col-md-8">
                <p>
                  Contactar con Cercas y Mallas Versátiles es muy sencillo.
                  Pensando siempre en ofrecer a nuestros clientes un servicio de
                  alta calidad ponemos a su disposición una Línea Telefónica sin
                  costo lada 800, un servicio de e-mails y whatsapp
                  personalizados de nuestra empresa que nos permitirán estar
                  siempre a su servicio de manera inmediata y GRATUITA. Todas
                  sus llamadas, e-mails y mensajes se canalizan de manera
                  directa a nuestro personal de atención a clientes,
                  concentrando toda la información en un sólo lugar, permitiendo
                  un control total de la misma y consecuentemente logrando un
                  mejor servicio para ustedes.
                </p>
              </div>
              <div class="col-md-4">
                <div class="container d-flex align-items-center py-3">
                  <video
                    class="video-intro"
                    controls
                    style="width: 100%"
                  >
                    <source src="../assets/follage2.mp4" type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="text-center mb-3">O llamanos a los numeros:</div>
              <div class="row">
                <div class="col-md-3">
                  <mdb-icon icon="phone-alt" class="mr-2 mb-2" />01 800 727 9437
                </div>
                <div class="col-md-3">
                  <mdb-icon icon="mobile-alt" class="mr-2 mb-2" />(744) 110 2290
                </div>
                <div class="col-md-3">
                  <mdb-icon icon="mobile-alt" class="mr-2 mb-2" />744 104 0211
                </div>
                <div class="col-md-3">
                  <mdb-icon icon="mobile-alt" class="mr-2 mb-2" />(228) 812 2865
                </div>
                <div class="col-md-3">
                  <mdb-icon icon="mobile-alt" class="mr-2" />228 148 3876
                </div>
                <div class="col-md-3">
                  <mdb-icon icon="mobile-alt" class="mr-2" />(229) 305 0511
                </div>
                <div class="col-md-3">
                  <mdb-icon icon="mobile-alt" class="mr-2" />(55) 6301 0567
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pb-3">.</div>
    
    <!-- modals -->

    <div>
    <mdb-modal :show="modalf" @close="modalf = false" centered success>
      <mdb-modal-header>
        <mdb-modal-title class="white-text">Muros verdes</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>Los muros verdes brindan elegancia y un toque único de estilo en cualquier lugar donde se
coloquen, además de poder tener distintos tipos de plantas artificiales. Los muros verdes
artificiales son la mejor opción para lugares con poca luz o cuando no se les puede dar
mantenimiento.</mdb-modal-body>
      <mdb-modal-footer>
        <a target="_blank" class="mx-auto" href="https://api.whatsapp.com/send?phone=5212293060014&text=Quiero%20hacer%20una%20cotizaci%C3%B3n%20de%20Muros%20verdes"><mdb-btn class="mx-auto" color="success" @click.native="modalf = false">Preguntar por el servicio<mdb-icon icon="leaf" class="ml-2" /></mdb-btn></a>
      </mdb-modal-footer>
    </mdb-modal>
  </div>

  <a target="_blank" href="https://api.whatsapp.com/message/OM43BU5OG5GNE1"
      ><mdb-btn
        color="succes"
        class="gradient-red-2 text-white"
        rounded
        id="fixedbutton"
        ><strong
          ><p class="mb-0">Presupuesto</p>
          gratis</strong
        ><mdb-icon icon="leaf" class="ml-2"/></mdb-btn
      ></a
    >
  </div>
</template>

<script>
import {
  mdbCard,
  mdbCardBody,
  mdbBtn,
  mdbIcon,
  mdbTbl,
  mdbTblBody,
  mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbView,
} from "mdbvue";

export default {
  data() {
    return {
      modalf: false,
    };
  },
  components: {
    mdbCard,
    mdbCardBody,
    mdbBtn,
    mdbIcon,
    mdbTbl,
    mdbTblBody,
    mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbView,
  },
  mounted() {
    document.title = 'Cercas y Mallas Versatiles - Follaje Artificial'
  },
};
</script>

<style>
.back {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("../assets/follaje.jpg") no-repeat center fixed;
  background-size: cover;
}

.my-hr-2 {
  border-top: 1px solid rgb(255, 255, 255);
  border-radius: 5px;
}
.green-1 {
  text-shadow: 2px 2px 8px #48e939;
  -webkit-text-stroke: 1px #ffff;
}

@import url("https://fonts.googleapis.com/css?family=Roboto:900");
/**
  * style variables
*/
/**
  * Control & indicator mixin
*/
.carousel-2 {
  height: 28rem;
  width: 50rem;
  overflow: hidden;
  text-align: center;
  position: relative;
  padding: 0;
  list-style: none;
  /**
  * Where the magic happens
  */
  /**
    * Control element - right/left arrows
  */
  /**
    * Element for holding slide indicators
  */
  /**
    * Indicator for indicating active slide
  */
  /**
    * Create rules for when slides are contained within a track
  */
}
.carousel-2__controls,
.carousel-2__activator {
  display: none;
}
.carousel-2__activator:nth-of-type(1):checked ~ .carousel-2__track {
  transform: translateX(0%);
}
.carousel-2__activator:nth-of-type(1):checked
  ~ .carousel-2__slide:nth-of-type(1) {
  transition: opacity 0.5s, transform 0.5s;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  transform: scale(1);
}
.carousel-2__activator:nth-of-type(1):checked
  ~ .carousel-2__controls:nth-of-type(1) {
  display: block;
  opacity: 1;
}
.carousel-2__activator:nth-of-type(1):checked
  ~ .carousel-2__indicators
  .carousel-2__indicator:nth-of-type(1) {
  opacity: 1;
}
.carousel-2__activator:nth-of-type(2):checked ~ .carousel-2__track {
  transform: translateX(-100%);
}
.carousel-2__activator:nth-of-type(2):checked
  ~ .carousel-2__slide:nth-of-type(2) {
  transition: opacity 0.5s, transform 0.5s;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  transform: scale(1);
}
.carousel-2__activator:nth-of-type(2):checked
  ~ .carousel-2__controls:nth-of-type(2) {
  display: block;
  opacity: 1;
}
.carousel-2__activator:nth-of-type(2):checked
  ~ .carousel-2__indicators
  .carousel-2__indicator:nth-of-type(2) {
  opacity: 1;
}
.carousel-2__activator:nth-of-type(3):checked ~ .carousel-2__track {
  transform: translateX(-200%);
}
.carousel-2__activator:nth-of-type(3):checked
  ~ .carousel-2__slide:nth-of-type(3) {
  transition: opacity 0.5s, transform 0.5s;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  transform: scale(1);
}
.carousel-2__activator:nth-of-type(3):checked
  ~ .carousel-2__controls:nth-of-type(3) {
  display: block;
  opacity: 1;
}
.carousel-2__activator:nth-of-type(3):checked
  ~ .carousel-2__indicators
  .carousel-2__indicator:nth-of-type(3) {
  opacity: 1;
}
.carousel-2__activator:nth-of-type(4):checked ~ .carousel-2__track {
  transform: translateX(-300%);
}
.carousel-2__activator:nth-of-type(4):checked
  ~ .carousel-2__slide:nth-of-type(4) {
  transition: opacity 0.5s, transform 0.5s;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  transform: scale(1);
}
.carousel-2__activator:nth-of-type(4):checked
  ~ .carousel-2__controls:nth-of-type(4) {
  display: block;
  opacity: 1;
}
.carousel-2__activator:nth-of-type(4):checked
  ~ .carousel-2__indicators
  .carousel-2__indicator:nth-of-type(4) {
  opacity: 1;
}
.carousel-2__activator:nth-of-type(5):checked ~ .carousel-2__track {
  transform: translateX(-400%);
}
.carousel-2__activator:nth-of-type(5):checked
  ~ .carousel-2__slide:nth-of-type(5) {
  transition: opacity 0.5s, transform 0.5s;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  transform: scale(1);
}
.carousel-2__activator:nth-of-type(5):checked
  ~ .carousel-2__controls:nth-of-type(5) {
  display: block;
  opacity: 1;
}
.carousel-2__activator:nth-of-type(5):checked
  ~ .carousel-2__indicators
  .carousel-2__indicator:nth-of-type(5) {
  opacity: 1;
}
.carousel-2__control {
  height: 30px;
  width: 30px;
  margin-top: -15px;
  top: 50%;
  position: absolute;
  display: block;
  cursor: pointer;
  border-width: 5px 5px 0 0;
  border-style: solid;
  border-color: #fafafa;
  opacity: 0.35;
  outline: 0;
  z-index: 3;
}
.carousel-2__control:hover {
  opacity: 1;
}
.carousel-2__control--backward {
  left: 10px;
  transform: rotate(-135deg);
}
.carousel-2__control--forward {
  right: 10px;
  transform: rotate(45deg);
}
.carousel-2__indicators {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
}
.carousel-2__indicator {
  height: 15px;
  width: 15px;
  border-radius: 100%;
  display: inline-block;
  z-index: 2;
  cursor: pointer;
  opacity: 0.35;
  margin: 0 2.5px 0 2.5px;
}
.carousel-2__indicator:hover {
  opacity: 0.75;
}
.carousel-2__track {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  margin: 0;
  transition: transform 0.5s ease 0s;
}
.carousel-2__track .carousel-2__slide {
  display: block;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
}
.carousel-2__track .carousel-2__slide:nth-of-type(1) {
  transform: translateX(0%);
}
.carousel-2__track .carousel-2__slide:nth-of-type(2) {
  transform: translateX(100%);
}
.carousel-2__track .carousel-2__slide:nth-of-type(3) {
  transform: translateX(200%);
}
.carousel-2__track .carousel-2__slide:nth-of-type(4) {
  transform: translateX(300%);
}
.carousel-2__track .carousel-2__slide:nth-of-type(5) {
  transform: translateX(400%);
}
.carousel-2--scale .carousel-2__slide {
  transform: scale(0);
}
.carousel-2__slide {
  height: 100%;
  position: absolute;
  overflow-y: auto;
  opacity: 0;
}
/**
  * Theming
*/
.my-carousel-2 {
  border-radius: 5px;
  margin: 30px;
}
.carousel-2__slide {
  overflow: hidden;
}
.carousel-2--thumb .carousel-2__indicator {
  height: 30px;
  width: 30px;
}
h1.car {
  font-size: 50px;
  line-height: 50px;
  color: #fafafa;
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  margin-top: -25px;
}

.h3-w {
  color: #fafafa;
}
.carousel-2__indicator {
  background-color: #fafafa;
}
.carousel-2__slide:nth-of-type(1),
.carousel-2--thumb
  .carousel-2__indicators
  .carousel-2__indicator:nth-of-type(1) {
  background-image: url("../assets/follaje/1.jpg");
  background-size: cover;
  background-position: center;
}
.carousel-2__slide:nth-of-type(2),
.carousel-2--thumb
  .carousel-2__indicators
  .carousel-2__indicator:nth-of-type(2) {
  background-image: url("../assets/follaje/2.jpg");
  background-size: cover;
  background-position: center;
}
.carousel-2__slide:nth-of-type(3),
.carousel-2--thumb
  .carousel-2__indicators
  .carousel-2__indicator:nth-of-type(3) {
  background-image: url("../assets/follaje/4.jpg");
  background-size: cover;
  background-position: center;
}
.carousel-2__slide:nth-of-type(4),
.carousel-2--thumb
  .carousel-2__indicators
  .carousel-2__indicator:nth-of-type(4) {
  background-image: url("../assets/follaje/6.jpg");
  background-size: cover;
  background-position: center;
}
.carousel-2__slide:nth-of-type(5),
.carousel-2--thumb
  .carousel-2__indicators
  .carousel-2__indicator:nth-of-type(5) {
  background-image: url("../assets/follaje/9.jpg");
  background-size: cover;
  background-position: center;
}
</style>